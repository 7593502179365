import styled from "styled-components"
import { useState } from 'react'
import { useEffect } from "react";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {

  return (
    <Router>
      <div>
        
        <Routes>
          <Route exact path="/" component={Home} element={<Home />} />
          <Route path="/main-site" component={MainSite} element={<MainSite />} />
           
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  const [startTransition, setStartTransition] = useState(false);
  const [explode, setExplode] = useState(false);
  const [destroy, setDestroy] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    if(startTransition){
      setTimeout(()=>{
        setExplode(true)
      }, 300)

      setTimeout(()=>{
        setDestroy(true)
      }, 1500)
    }
   
  }, [startTransition])

  useEffect(()=>{
    if(destroy){
      navigate("/main-site")
    }
  }, [destroy])

  return (
    <StyledDiv style={{ background: "#FF2B6D"}}>
    <SuperImage onClick={()=>setStartTransition(true)} src="JakeNew1.gif" width={destroy?"0px":"100%"} height="100%" />
    <BlackDotMode show={startTransition} explode={explode} />
    
    </StyledDiv>
  )
}

function MainSite () {
  return <div style={{background:"black", marginTop:"-19px", width:"100%", minHeight:"800px"}}>
    <p style={{color:"white", paddingLeft:"30px", paddingTop:"30px"}}>hey it's my site</p></div>
}

const BlackDotMode = styled.div`
display:${(props)=>props.show ? "flex" : "none"};
background:black;
left:${(props)=>props.explode ? "0" : "39%"};
width:${(props)=>props.explode ? "100%" : "12px"};
 height:${(props)=>props.explode ? "100%" : "16px"};
 top:${(props)=>props.explode ? "0" : "59%"};
bottom:0;
 position:absolute;
 zIndex:1000; 

 transition:all 1s ease-in;
`
const StyledDiv = styled.div`
  @media(max-width:500px){
   
    height:812px;
  }
`

const SuperImage = styled.img`
cursor:pointer;
@media(max-width:500px){
 height:330px;
 
}
`
